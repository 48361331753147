// src/components/Pricing.tsx
import React, { useEffect, useState } from 'react';
import paymentService from '../services/paymentService';
import { Link } from 'react-router-dom';
import { RoutePaths } from '../routes/RoutePaths';
import { Helmet } from 'react-helmet';
import BuyTheProPlan from '../htmlBlocks/ByTheProPlan';
import BuyTheUnlimitedPlan from '../htmlBlocks/ByTheUnlimitedPlan';
import { stripeIDs } from '../config/config';

const Pricing: React.FC = () => {
    const [unlimitedPlanPrice, setUnlimitedPlanPrice] = useState<number | null>(null);
    const [unlimitedPlanCurrency, setUnlimitedPlanCurrency] = useState<string>('USD');
    const [unlimitedPlanInterval, setUnlimitedPlanInterval] = useState<string>('month');
    const [unlimitedPlanID, setUnlimitedPlanID] = useState<string>(stripeIDs.STRIPE_SUBSCRIPTION_UNLTD);

    const [proPlanPrice, setProPlanPrice] = useState<number | null>(null);
    const [proPlanCurrency, setProPlanCurrency] = useState<string>('USD');
    const [proPlanInterval, setProPlanInterval] = useState<string>('month');
    const [proPlanID, setProPlanID] = useState<string>(stripeIDs.STRIPE_SUBSCRIPTION_PRO);

    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    // Fetch both Pro and Unlimited subscription prices
    useEffect(() => {
        const fetchPrices = async () => {
            try {
                // Fetch Unlimited Subscription Price
                const unlimitedData = await paymentService.fetchUnltdSubscription();
                if (unlimitedData && unlimitedData.length > 0) {
                    const priceData = unlimitedData[0];  // Access the first price in the array
                    setUnlimitedPlanPrice(priceData.amount);  // Set the Unlimited plan price
                    setUnlimitedPlanCurrency(priceData.currency || 'USD'); // Set the Unlimited plan currency
                    setUnlimitedPlanInterval(priceData.interval || 'month'); // Set the Unlimited plan interval
                    setUnlimitedPlanID(stripeIDs.STRIPE_SUBSCRIPTION_UNLTD);  // Set the Unlimited plan ID
                } else {
                    setError('Failed to fetch unlimited plan price.');
                }

                // Fetch Pro Subscription Price
                const proData = await paymentService.fetchProSubscription();
                if (proData && proData.length > 0) {
                    const priceData = proData[0];  // Access the first price in the array
                    setProPlanPrice(priceData.amount);  // Set the Pro plan price
                    setProPlanCurrency(priceData.currency || 'USD'); // Set the Pro plan currency
                    setProPlanInterval(priceData.interval || 'month'); // Set the Pro plan interval
                    setProPlanID(stripeIDs.STRIPE_SUBSCRIPTION_PRO);  // Set the Pro plan ID
                } else {
                    setError('Failed to fetch pro plan price.');
                }
            } catch (error) {
                setError('Error fetching prices. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        fetchPrices();
    }, []);

    return (
        <div>
            <Helmet>
                <title>Pricing page for Patalytica</title>
                <meta name="description" content="This page shows the pricing options for Patalytica" />
                <meta property="og:title" content="Pricing options for Patalytica" />
                <meta property="og:description" content="Pricing tiers for Patalytica" />
            </Helmet>
            <section className="py-8 bg-white dark:bg-gray-900 lg:py-24" id="Page">
                <div className="px-4 mx-auto max-w-8xl lg:px-4">
                    <h2 className="mb-4 text-3xl font-bold text-gray-900 lg:font-extrabold lg:text-4xl lg:leading-snug dark:text-white lg:text-center 2xl:px-48">Choose the right plan for your business
                    </h2>
                    <p className="mb-10 text-lg font-normal text-gray-500 dark:text-gray-400 lg:text-center lg:text-xl lg:mb-16">Used by freelancers, startups, companies, and enterprise-level corporations all over the world.
                    </p>
                    <div className="grid grid-cols-1 md:grid-cols-4 gap-x-16 bg-gray-50 dark:bg-gray-800 p-8 rounded-t-lg">
                        <div className="hidden md:block">
                        </div>
                        <div className="mb-8 md:mb-0">
                            <h3 className="mb-1 text-lg font-bold text-gray-900 dark:text-white">14-day trial version
                            </h3>
                            <span className="block mb-4 text-4xl font-extrabold text-gray-900 dark:text-white">Free
                            </span>
                            <p className="mb-4 text-sm text-gray-500 dark:text-gray-400">Get started with a limited number of searches. Automatically upgrade to a <span className='font-bold'>Pro</span> plan when you reach the limit.
                            </p>
                            <Link to={RoutePaths.Register} className="block w-full px-6 py-2 font-medium text-center text-gray-900 bg-white border border-gray-200 rounded-lg dark:border-gray-600 dark:bg-gray-800 dark:text-white dark:hover:text-gray-100 hover:bg-gray-100 hover:text-blue-700 dark: dark:hover:bg-gray-700 focus:outline-none focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700">Get started
                            </Link>
                        </div>
                        <div className="mb-8 md:mb-0">
                            <h3 className="mb-1 text-lg font-bold text-gray-900 dark:text-white">Unlimited
                            </h3>
                            <span className="mb-4 text-4xl font-extrabold text-gray-900 dark:text-white">
                                {loading ? (
                                    <p>Loading price...</p>
                                ) : error ? (
                                    <p>{error}</p>
                                ) : (
                                    <p>
                                        {unlimitedPlanCurrency.toUpperCase()} {unlimitedPlanPrice?.toFixed(2)} / {unlimitedPlanInterval}
                                    </p>
                                )}
                            </span>
                            <p className="my-4 text-sm text-gray-500 dark:text-gray-400">Recommended for IP professionals and and companies with a substantial interest in monitoring patent families.
                            </p>
                            <BuyTheUnlimitedPlan
                                unlimitedPlanID={unlimitedPlanID}
                            />
                        </div>
                        <div className="mb-3 md:mb-0">
                            <h3 className="mb-1 text-lg font-bold text-gray-900 dark:text-white">Pro
                            </h3>
                            <span className="block mb-4 text-4xl font-extrabold text-gray-900 dark:text-white">
                                {loading ? (
                                    <p>Loading price...</p>
                                ) : error ? (
                                    <p>{error}</p>
                                ) : (
                                    <p>
                                        {proPlanCurrency.toUpperCase()} {proPlanPrice?.toFixed(2)} / {proPlanInterval}
                                    </p>
                                )}

                            </span>
                            <p className="mb-4 text-sm text-gray-500 dark:text-gray-400">Recommended for individuals, small businesses, and startups with a limited budget.
                            </p>
                            <BuyTheProPlan
                                proPlanID={proPlanID}
                            />
                        </div>
                    </div>
                    <div className="overflow-x-auto">
                        <div className="overflow-hidden min-w-max">
                            <div className="grid grid-cols-4 p-4 text-sm font-medium text-gray-900 bg-gray-100 border-t border-b border-gray-200 gap-x-16 dark:bg-gray-800 dark:border-gray-700 dark:text-white">
                                <div>Patalytica Patent Familiy Tree Timelines
                                </div>
                                <div>14-day trial version
                                </div>
                                <div>Unlimited
                                </div>
                                <div>Pro
                                </div>
                            </div>
                            <div className="grid grid-cols-4 px-4 py-5 text-sm text-gray-700 border-b border-gray-200 gap-x-16 dark:border-gray-700">
                                <div className="text-gray-500 dark:text-gray-400">
                                    Number of searches
                                </div>
                                <div className="text-gray-500 dark:text-gray-400">
                                    1/day
                                </div>
                                <div className="text-gray-500 dark:text-gray-400">
                                    Unlimited
                                </div>
                                <div className="text-gray-500 dark:text-gray-400">
                                    5/day
                                </div>
                            </div>
                            <div className="grid grid-cols-4 px-4 py-5 text-sm text-gray-700 border-b border-gray-200 gap-x-16 dark:border-gray-700">
                                <div className="text-gray-500 dark:text-gray-400">
                                    Basic docketing
                                </div>
                                <div className="text-green-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="w-5 h-5">
                                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd">
                                        </path>
                                    </svg>
                                </div>
                                <div className="text-green-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="w-5 h-5">
                                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd">
                                        </path>
                                    </svg>
                                </div>
                                <div className="text-green-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="w-5 h-5">
                                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd">
                                        </path>
                                    </svg>
                                </div>
                            </div>
                            <div className="grid grid-cols-4 px-4 py-5 text-sm text-gray-700 border-b border-gray-200 gap-x-16 dark:border-gray-700">
                                <div className="text-gray-500 dark:text-gray-400">Due-date calculations
                                </div>
                                <div className="text-green-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="w-5 h-5">
                                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd">
                                        </path>
                                    </svg>
                                </div>
                                <div className="text-green-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="w-5 h-5">
                                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd">
                                        </path>
                                    </svg>
                                </div>
                                <div className="text-green-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="w-5 h-5">
                                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd">
                                        </path>
                                    </svg>
                                </div>
                            </div>
                            <div className="grid grid-cols-4 px-4 py-5 text-sm text-gray-700 border-b border-gray-200 gap-x-16 dark:border-gray-700">
                                <div className="text-gray-500 dark:text-gray-400">Custom docketing containers
                                </div>
                                <div className="text-red-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="w-5 h-5">
                                        <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd">
                                        </path>
                                    </svg>
                                </div>
                                <div className="text-green-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="w-5 h-5">
                                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd">
                                        </path>
                                    </svg>
                                </div>
                                <div className="text-green-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="w-5 h-5">
                                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd">
                                        </path>
                                    </svg>
                                </div>
                            </div>
                            <div className="grid grid-cols-4 px-4 py-5 text-sm text-gray-700 border-b border-gray-200 gap-x-16 dark:border-gray-700">
                                <div className="text-gray-500 dark:text-gray-400">Due-date reminders
                                </div>
                                <div className="text-red-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="w-5 h-5">
                                        <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd">
                                        </path>
                                    </svg>
                                </div>
                                <div className="text-green-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="w-5 h-5">
                                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd">
                                        </path>
                                    </svg>
                                </div>
                                <div className="text-green-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="w-5 h-5">
                                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd">
                                        </path>
                                    </svg>
                                </div>
                            </div>
                            <div className="grid grid-cols-4 p-4 text-sm font-medium text-gray-900 bg-gray-100 border-b border-gray-200 gap-x-16 dark:bg-gray-800 dark:border-gray-700 dark:text-white">
                                <div>Patalytica Support
                                </div>
                                <div>14-day trial version
                                </div>
                                <div>Unlimited
                                </div>
                                <div>Pro
                                </div>
                            </div>
                            <div className="grid grid-cols-4 px-4 py-5 text-sm text-gray-700 border-b border-gray-200 gap-x-16 dark:border-gray-700">
                                <div className="text-gray-500 dark:text-gray-400">Bug reports & feature requests
                                </div>
                                <div className="text-green-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="w-5 h-5">
                                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd">
                                        </path>
                                    </svg>
                                </div>
                                <div className="text-green-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="w-5 h-5">
                                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd">
                                        </path>
                                    </svg>
                                </div>
                                <div className="text-green-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="w-5 h-5">
                                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd">
                                        </path>
                                    </svg>
                                </div>
                            </div>

                            <div className="grid grid-cols-4 px-4 py-5 text-sm text-gray-700 border-b border-gray-200 gap-x-16 dark:border-gray-700">
                                <div className="text-gray-500 dark:text-gray-400">Support duration
                                </div>
                                <div className="text-red-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="w-5 h-5">
                                        <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd">
                                        </path>
                                    </svg>
                                </div>
                                <div className="text-gray-500 dark:text-gray-400">duration of subscription
                                </div>
                                <div className="text-gray-500 dark:text-gray-400">duration of subscription
                                </div>
                            </div>
                            <div className="grid grid-cols-4 p-4 text-sm font-medium text-gray-900 bg-gray-100 border-b border-gray-200 gap-x-16 dark:bg-gray-800 dark:border-gray-700 dark:text-white">
                                <div className="text-gray-500 dark:text-gray-400">Updates and beta feature access
                                </div>
                                <div className="text-gray-500 dark:text-gray-400">14-day trial version
                                </div>
                                <div className="text-gray-500 dark:text-gray-400">Unlimited
                                </div>
                                <div className="text-gray-500 dark:text-gray-400">Pro
                                </div>
                            </div>

                            <div className="grid grid-cols-4 px-4 py-5 text-sm text-gray-700 border-b border-gray-200 gap-x-16 dark:border-gray-700">
                                <div className="text-gray-500 dark:text-gray-400">Updates
                                </div>
                                <div className="text-green-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="w-5 h-5">
                                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd">
                                        </path>
                                    </svg>
                                </div>
                                <div className="text-green-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="w-5 h-5">
                                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd">
                                        </path>
                                    </svg>
                                </div>
                                <div className="text-green-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="w-5 h-5">
                                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd">
                                        </path>
                                    </svg>
                                </div>
                            </div>
                            <div className="grid grid-cols-4 px-4 py-5 text-sm text-gray-700 border-b border-gray-200 gap-x-16 dark:border-gray-700">
                                <div className="text-gray-500 dark:text-gray-400">Access to beta features
                                </div>
                                <div className="text-red-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="w-5 h-5">
                                        <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd">
                                        </path>
                                    </svg>
                                </div>
                                <div className="text-green-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="w-5 h-5">
                                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd">
                                        </path>
                                    </svg>
                                </div>
                                <div className="text-green-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="w-5 h-5">
                                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd">
                                        </path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-4 gap-x-16 bg-gray-50 dark:bg-gray-800 p-8 rounded-t-lg">
                        <div className="hidden md:block">
                        </div>
                        <div className="mb-8 md:mb-0">
                            <h3 className="mb-1 text-lg font-bold text-gray-900 dark:text-white">14-day trial version
                            </h3>
                            <span className="block mb-4 text-4xl font-extrabold text-gray-900 dark:text-white">Free
                            </span>
                            <p className="mb-4 text-sm text-gray-500 dark:text-gray-400">Get started with a limited number of searches. Automatically upgrade to a <span className='font-bold'>Pro</span> plan when you reach the limit.
                            </p>
                            <Link to={RoutePaths.Register} className="block w-full px-6 py-2 font-medium text-center text-gray-900 bg-white border border-gray-200 rounded-lg dark:border-gray-600 dark:bg-gray-800 dark:text-white dark:hover:text-gray-100 hover:bg-gray-100 hover:text-blue-700 dark: dark:hover:bg-gray-700 focus:outline-none focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700">Get started
                            </Link>
                        </div>
                        <div className="mb-8 md:mb-0">
                            <h3 className="mb-1 text-lg font-bold text-gray-900 dark:text-white">Unlimited
                            </h3>
                            <span className="mb-4 text-4xl font-extrabold text-gray-900 dark:text-white">
                                {loading ? (
                                    <p>Loading price...</p>
                                ) : error ? (
                                    <p>{error}</p>
                                ) : (
                                    <p>
                                        {unlimitedPlanCurrency.toUpperCase()} {unlimitedPlanPrice?.toFixed(2)} / {unlimitedPlanInterval}
                                    </p>
                                )}
                            </span>
                            <p className="my-4 text-sm text-gray-500 dark:text-gray-400">Recommended for IP professionals and and companies with a substantial interest in monitoring patent families.
                            </p>
                            <BuyTheUnlimitedPlan
                                unlimitedPlanID={unlimitedPlanID}
                            />
                        </div>
                        <div className="mb-3 md:mb-0">
                            <h3 className="mb-1 text-lg font-bold text-gray-900 dark:text-white">Pro
                            </h3>
                            <span className="block mb-4 text-4xl font-extrabold text-gray-900 dark:text-white">
                                {loading ? (
                                    <p>Loading price...</p>
                                ) : error ? (
                                    <p>{error}</p>
                                ) : (
                                    <p>
                                        {proPlanCurrency.toUpperCase()} {proPlanPrice?.toFixed(2)} / {proPlanInterval}
                                    </p>
                                )}

                            </span>
                            <p className="mb-4 text-sm text-gray-500 dark:text-gray-400">Recommended for individuals, small businesses, and startups with a limited budget.
                            </p>
                            <BuyTheProPlan
                                proPlanID={proPlanID}
                            />
                        </div>
                    </div>
                </div>
            </section>
        </div>

    );
};

export default Pricing;