// src/services/paymentService.ts
import axios from 'axios';
import config from '../config/config';
import { axiosBase } from './axiosConfig';
import { stripeIDs } from '../config/config';

const { SERVER_BASE_URL, SERVER_API_STRIPE } = config;

const createPaymentIntent = async (amount: number, email: string, productId: string) => {
    try {
        const response = await axiosBase.post(`${SERVER_BASE_URL}${SERVER_API_STRIPE}create-payment-intent`, {
            amount,
            email,
            productId,
        });

        return { clientSecret: response.data.clientSecret, error: null };
    } catch (error: unknown) {
        console.error('Error in createPaymentIntent:', error);

        if (axios.isAxiosError(error)) {
            // Axios specific error
            if (error.response) {
                console.error('Server responded with:', error.response.data);
                return { clientSecret: null, error: error.response.data };
            } else {
                return { clientSecret: null, error: 'An unknown error occurred.' };
            }
        } else {
            // Generic or unexpected error
            return { clientSecret: null, error: 'An unexpected error occurred.' };
        }
    }
};

const fetchUnltdSubscription = async () => {
    try {
        const response = await axiosBase.get(`${SERVER_BASE_URL}${SERVER_API_STRIPE}get-prices/${stripeIDs.STRIPE_SUBSCRIPTION_UNLTD}`);
        return response.data;
    } catch (error: unknown) {
        console.error('Error in fetchUnltdSubscription:', error);

        if (axios.isAxiosError(error)) {
            // Axios specific error
            if (error.response) {
                console.error('Server responded with:', error.response.data);
                return error.response.data;
            } else {
                return 'An unknown error occurred.';
            }
        } else {
            // Generic or unexpected error
            return 'An unexpected error occurred.';
        }
    }
};

const fetchProSubscription = async () => {
    try {
        const response = await axiosBase.get(`${SERVER_BASE_URL}${SERVER_API_STRIPE}get-prices/${stripeIDs.STRIPE_SUBSCRIPTION_PRO}`);
        return response.data;
    } catch (error: unknown) {
        console.error('Error in fetchUnltdSubscription:', error);

        if (axios.isAxiosError(error)) {
            // Axios specific error
            if (error.response) {
                console.error('Server responded with:', error.response.data);
                return error.response.data;
            } else {
                return 'An unknown error occurred.';
            }
        } else {
            // Generic or unexpected error
            return 'An unexpected error occurred.';
        }
    }
};

const fetchProduct = async (productId: string) => {
    try {
        const response = await axiosBase.get(`${SERVER_BASE_URL}${SERVER_API_STRIPE}get-product/${productId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching product:', error);
        throw error;
    }
};

export default {
    createPaymentIntent,
    fetchUnltdSubscription,
    fetchProSubscription,
    fetchProduct
};