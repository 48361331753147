// src/htmlBlocks/ByTheUnlimitedPlanProps.tsx
import React from "react";
import { Link } from 'react-router-dom';
import { RoutePaths } from '../routes/RoutePaths';
interface ByTheUnlimitedPlanProps {
  unlimitedPlanID: string;
}

const ByTheUnlimitedPlan: React.FC<ByTheUnlimitedPlanProps> = ({ unlimitedPlanID }) => {
  return (
    <Link
      to={`${RoutePaths.Checkout}?prod=${unlimitedPlanID}`}
      className="block w-full px-6 py-2 mb-3 text-base font-medium text-center text-white bg-blue-700 dark:bg-blue-600 hover:bg-blue-800 rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-300 dark:hover:bg-blue-700 md:mr-5 md:mb-0">
      Buy the Unlimited plan
    </Link>
  );
};

export default ByTheUnlimitedPlan;